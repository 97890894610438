import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { FLAMINGO_APP_CONFIG, FlamingoAppConfig } from '@flamingo/flamingo.config';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FlamingoHttpService {
  private readonly commonHeaders: HttpHeaders;
  private readonly baseUrl: string;

  constructor(
    private http: HttpClient,
    @Inject(FLAMINGO_APP_CONFIG) config: FlamingoAppConfig,
  ) {
    this.commonHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    this.baseUrl = config.baseUrl || '';
  }

  private static getFileName(response: HttpResponse<Blob>) {
    let filename = 'download';
    const disposition =
      response.headers.get('content-disposition') || response.headers.get('Content-Disposition');
    if (disposition && disposition.indexOf('attachment') !== -1) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(disposition);
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, '');
      }
    }
    return filename;
  }

  public get<T>(url: string, params?: HttpParams, headers?: HttpHeaders): Observable<T> {
    const URL = this.baseUrl + url;

    return this.http.get<T>(URL, {
      headers: headers ? headers : this.commonHeaders,
      params,
      responseType: 'json',
    });
  }

  public getDownload(
    url: string,
    params?: HttpParams,
    headers?: HttpHeaders,
  ): Observable<HttpResponse<Blob>> {
    const URL = this.baseUrl + url;

    return this.http.get(URL, {
      headers: headers ? headers : this.commonHeaders,
      params,
      responseType: 'blob',
      observe: 'response',
    })
      .pipe(
        tap((response: HttpResponse<Blob>) => {
          let filename: string = FlamingoHttpService.getFileName(response);
          const obj = window.URL.createObjectURL(new Blob([response.body!], { type: response.body!.type }));
          const link = window.document.createElement('a');
          link.download = filename;
          link.href = obj;
          link.click();
        }),
      );
  }

  public post<T>(
    url: string,
    body?: any | null,
    params?: HttpParams,
    headers?: HttpHeaders,
  ): Observable<T> {
    const URL = this.baseUrl + url;

    return this.http.post<T>(URL, body, {
      headers: headers ? headers : this.commonHeaders,
      params,
      responseType: 'json',
    });
  }

  public postDownload(
    url: string,
    body?: any | null,
    headers?: HttpHeaders,
  ): Observable<HttpResponse<Blob>> {
    const URL = this.baseUrl + url;

    return this.http.post(URL, body, {
      headers: headers ? headers : this.commonHeaders,
      responseType: 'blob',
      observe: 'response',
    })
      .pipe(
        tap((response: HttpResponse<Blob>) => {
          let filename: string = FlamingoHttpService.getFileName(response);
          const obj = window.URL.createObjectURL(new Blob([response.body!], {type: response.body!.type}));
          const link = window.document.createElement('a');
          link.download = filename;
          link.href = obj;
          link.click();
        }),
    );
  }

  public put<T>(
    url: string,
    body?: any | null,
    params?: HttpParams,
    headers?: HttpHeaders,
  ): Observable<T> {
    const URL = this.baseUrl + url;

    return this.http.put<T>(URL, body, {
      headers: headers ? headers : this.commonHeaders,
      params,
      responseType: 'json',
    });
  }

  public patch<T>(
    url: string,
    body?: any | null,
    params?: HttpParams,
    headers?: HttpHeaders,
  ): Observable<T> {
    const URL = this.baseUrl + url;

    return this.http.put<T>(URL, body, {
      headers: headers ? headers : this.commonHeaders,
      params,
      responseType: 'json',
    });
  }

  public delete<T>(url: string, body?: any, params?: HttpParams, headers?: HttpHeaders): Observable<T> {
    const URL = this.baseUrl + url;

    return this.http.delete<T>(URL, {
      headers: headers ? headers : this.commonHeaders,
      params,
      responseType: 'json',
      body: body || null,
    });
  }

}
